<template>
  <div class="pei">
    <en-table-layout :table-data="dataTable">

      <template slot="toolbar">
        <div class="buttonbc" style="top: 0;">
          <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
            <img src="@/assets/back.png" alt="">
          </el-button>
        </div>
        <div class="col-auto" style="width: 100%;">
          <el-form-item label="申请时间">
            <el-date-picker style="width: 220px;" v-model="daterangeDate" type="daterange" @change="daterangeDateChange"
              range-separator="-" size="medium" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="发票编号">
            <el-input v-model="params.batch_sn" size="medium" placeholder="请输入发票编号" />
          </el-form-item>
          <el-form-item>
            <el-form-item label="开票状态">
              <el-select v-model="params.status" size="medium">
                <el-option label="全部" value></el-option>
                <el-option v-for="phase in phases" :key="phase.id" :label="phase.cname" :value="phase.id"></el-option>
              </el-select>
            </el-form-item>
            <el-button size="small" type="primary" @click="getHistoryList">
              搜索
            </el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column align="center" prop="batch_sn" label="发票编号"></el-table-column>
        <el-table-column align="center" prop="create_time" label="申请时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="开票状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === -1">已拒绝</span>
            <span v-if="scope.row.status === 0">审核中</span>
            <span v-if="scope.row.status === 1">已通过</span>
            <span v-if="scope.row.status === 2">已邮寄</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="receipt_amount" label="发票金额"></el-table-column>
        <el-table-column prop="operator" label="操作人" align="center"></el-table-column>
        <el-table-column align="center" prop="cz" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)">查看详情
            </el-button>
          </template>
        </el-table-column>
      </template>

      <template>
        <el-pagination slot="pagination" v-if="dataTable" @size-change="handlePageSizeChange"
          @current-change="handlePageCurrentChange" :current-page="paramsData.page_no" :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout" background :page-size="paramsData.page_size" :total="paramsData.data_total">
        </el-pagination>
      </template>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_goods from '@/api/goods';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'Invoicemangerjilu',
  components: { EnTableLayout },
  data () {
    return {
      is: '',
      dataTable: [],
      paramsData: {
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      currentPage3: 1,
      phases: [
        {
          cname: '审核中',
          id: '0'
        },
        {
          cname: '已通过',
          id: '1'
        },
        {
          cname: '已拒绝',
          id: '-1'
        },
        {
          cname: '已邮寄',
          id: '2'
        }
      ],

      formInline: {},
      params: {
        status: '',
        batch_sn: ''
      },
      daterangeDate: []
    };
  },
  watch: {
    daterangeDate: {
      handler: 'daterangeDateChange',
      deep: true
    }
  },
  created () {
    // this.getzdlist();
  },
  mounted () {
    this.is = this.$route.query.is;
    this.getHistoryList();
  },
  methods: {
    getHistoryList () {
      let params = {
        ...this.params,
        ...this.paramsData,
        type: this.is
      };
      API_goods.getHistoryList(params).then(res => {
        this.dataTable = res.data;
        this.paramsData.page_size = res.page_size;
        this.paramsData.page_no = res.page_no;
        this.paramsData.data_total = res.data_total;
      });
    },
    handlePageSizeChange (page_size) {
      this.paramsData.page_size = page_size;
      this.getHistoryList();
    },
    handlePageCurrentChange (page_no) {
      this.paramsData.page_no = page_no;
      this.getHistoryList();
    },
    handleEdit (row) {
      this.$router.push({
        path: `/finance/Invoicemanger/Invoicemangerjiludetail`,
        query: {
          id: row.history_id,
          is: this.is
        }
      });
    },

    getzdlist () {
    },

    inpuchange () {
      console.log(this.params);
      this.getzdlist();
    },
    daterangeDateChange (val) {
      if (this.daterangeDate && this.daterangeDate.length > 0) {
        this.params.start_time =
          new Date(this.daterangeDate[0]).getTime() / 1000;
        this.params.end_time = new Date(this.daterangeDate[1]).getTime() / 1000 + 86399;
      } else {
        this.params.start_time = '';
        this.params.end_time = '';
      }
      // this.params.start_time = val ? val[0] / 1000 : "";
      // this.params.end_time = val ? val[1] / 1000 : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.pei {
  position: relative;
}

.buttonbc {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}
</style>
